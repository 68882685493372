import styled from 'styled-components';
import { theme } from 'styles/styled';

import Heading from 'components/Heading/Heading';
import Text from 'components/Text/Text';

import _SearchIcon from 'images/Icons/SearchIcon';

export const Glossary = styled.section`
  background: ${theme.palette.light};
  padding: 0 20px;
  margin-bottom: -150px;
  padding-bottom: 72px;
  margin-top: -70px;
  padding-top: 76px;

  @media ${theme.devices.medium} {
    padding-bottom: 150px;
  }
`;

export const GlossaryHeading = styled(Heading)`
  font-size: ${theme.fontSize.pageTitleMobile};
  margin-top: 20px;
  margin-bottom: 16px;

  @media ${theme.devices.medium} {
    margin-bottom: 46px;
    font-size: 50px;
  }
`;

export const AlphabetList = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 32px;
`;

export const AlphabetListItem = styled.li`
  flex-basis: calc(960px / 26);
  text-align: center;
  margin-top: 24px;
`;

export const Letter = styled(Text)<{ active: boolean }>`
  font-size: ${theme.fontSize.headingMobile};
  text-transform: uppercase;
  text-decoration: ${(props) => (props.active ? 'underline' : 'none')};
  text-underline-offset: 10px;
  user-select: none;
  cursor: pointer;
  font-weight: ${(props) =>
    props.active ? theme.fontWeight.medium : theme.fontWeight.light};
  color: ${(props) =>
    props.active ? theme.palette.primary : theme.palette.dark};

  :disabled {
    opacity: 0.25;
    cursor: auto;
  }
`;

export const SearchInput = styled.input`
  border: 0;
  padding: 16px 40px 16px 16px;
  width: 100%;
  background: ${theme.palette.secondaryLight};
  outline: none;

  @media ${theme.devices.medium} {
    padding: 20px 60px 20px 10px;
  }
`;

export const GlossaryContent = styled.ul`
  display: grid;
  grid-template-columns: minmax(auto, 460px);
  grid-column-gap: 40px;
  grid-row-gap: 36px;

  @media ${theme.devices.small} {
    grid-template-columns: repeat(2, minmax(auto, 460px));
  }
`;

export const PhraseTitle = styled(Text)`
  font-size: ${theme.fontSize.base};
  margin-bottom: 0;
  font-weight: ${theme.fontWeight.medium};

  @media ${theme.devices.small} {
    margin-bottom: 12px;
    font-size: ${theme.fontSize.title};
  }
`;

export const SearchInputWrapper = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 42px;
`;

export const SearchIcon = styled(_SearchIcon)`
  position: absolute;
  height: 24px;
  width: 24px;

  top: 13px;
  right: 13px;

  @media ${theme.devices.medium} {
    height: 30px;
    width: 30px;

    top: 15px;
    right: 15px;
  }
`;
