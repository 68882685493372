import styled from 'styles/styled';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.button<{ isActive: boolean }>`
  //styleName: mobile/m_H4;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.25px;

  background: ${({ isActive, theme }) =>
    isActive ? theme.palette.primary : 'transparent'};
  border: 1px solid ${({ theme }) => theme.palette.primary};
  border-radius: 13.5px;
  color: ${({ isActive, theme }) =>
    theme.palette[isActive ? 'light' : 'primary']};
  cursor: pointer;
  padding: 4px 10px;
  text-transform: uppercase;
  margin: 0 5px 5px 0;
  transition: ${({ theme }) => theme.ui.transition('all')};

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary};
    color: ${({ theme }) => theme.palette.light};
  }
`;
