export type Colors = 'black' | 'white' | 'mcblue01' | 'mcblue03' | 'gray';
export type Breakpoints = 'medium' | 'large';

type Tokens = {
  palette: Record<Colors, string>;
  breakpoints: Record<Breakpoints, number>;
};

export const tokens: Tokens = {
  palette: {
    black: '#000000',
    white: '#FFFFFF',
    mcblue01: '#28AAFF',
    mcblue03: '#93D4FF',
    gray: '#BFBFBF',
  },
  breakpoints: {
    medium: 768,
    large: 992,
  },
};

export type BaseProps = {
  isRtl: boolean;
  breakpoint?: Breakpoints;
  color?: Colors;
};
