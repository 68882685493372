import styled from 'styled-components';
import { ThemeInterface, theme } from 'styles/styled';

interface HeadingProps {
  size: keyof ThemeInterface['fontSize'];
  color: keyof ThemeInterface['palette'];
  weight: keyof ThemeInterface['fontWeight'];
  letterSpacing: keyof ThemeInterface['letterSpacings'];
  isRtl: boolean;
}

const Heading = styled.header<HeadingProps>`
  display: inline-block;
  letter-spacing: ${(props) => theme.letterSpacings[props.letterSpacing]};
  font-size: ${(props) => theme.fontSize[props.size]};
  color: ${(props) => theme.palette[props.color]};
  font-weight: ${(props) => theme.fontWeight[props.weight]};
  direction: ${(props) => (props.isRtl ? 'rtl' : 'ltr')};
`;

export default Heading;
