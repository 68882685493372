import styled from 'styles/styled';
import { tokens, BaseProps } from './tokens';

export const Paragraph = styled.p<BaseProps>`
  /* mobile/m_H5 */

  color: ${(props) => tokens.palette[props.color ?? 'black']};
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;

  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;

  white-space: break-spaces;
  direction: ${(props) => (props.isRtl ? 'rtl' : 'ltr')};
  text-decoration: none;

  /* desktop/H5 */
  @media (min-width: ${(props) =>
      tokens.breakpoints[props.breakpoint ?? 'large']}px) {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: unset;
  }
`;

export const UppercasedParagraph = styled(Paragraph)`
  text-transform: uppercase;
`;
