import React from 'react';
import { graphql } from 'gatsby';

import {
  translationsFactory,
  TranslationResult,
  TranslationsContext,
} from 'settings/i18n';
import menuLinks from 'settings/menuLinks';
import { BlogPost } from 'types';
import { deduplicateArray } from 'utils';
import SEO, { SEOMeta } from 'components/SEO/SEO';

import ClientOnly from 'components/ClientOnly/ClientOnly';
import EducationNavigation from 'components/EducationNavigation';
import Footer from 'components/Footer';
import Blog from 'components/Blog';

const getTagsFromBlogPosts = (blogPosts: BlogPost[]) =>
  blogPosts
    .map(({ tags }) => tags.nodes)
    .flat()
    .map(({ name }) => name);

interface Props {
  data: TranslationResult & SEOMeta;
}

const BlogPage = ({ data }: Props) => {
  const combinedTranslations = {
    wpPage: {
      id: 'translations',
      translation: data?.homepage?.translation,
    },
  };
  const t = translationsFactory(combinedTranslations);

  const blogPosts = (data.blogPosts.nodes as unknown) as BlogPost[];
  const tags = deduplicateArray(getTagsFromBlogPosts(blogPosts));

  return (
    <TranslationsContext.Provider value={t}>
      <SEO metadata={data.metadata || undefined} url={menuLinks.blog} />
      <ClientOnly>
        <EducationNavigation />
        <main>
          <Blog blogPosts={blogPosts} wpTags={tags} />
        </main>
        <Footer />
      </ClientOnly>
    </TranslationsContext.Provider>
  );
};

export default BlogPage;

export const query = graphql`
  query($locale: String!) {
    metadata: wpPage(
      slug: { regex: "/\\b(\\w*blog-main-page\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...MetaData
    }
    homepage: wpPage(
      slug: { regex: "/\\b(\\w*home-page\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...HeaderTranslation
      ...FooterTranslation
      ...AboutTranslation
      ...QITranslation
      translation {
        blog
      }
    }
    blogPosts: allWpPost(
        filter: {
          categories: {
            nodes: { elemMatch: { slug: { regex: "/blog-/" } } }
          }
          language: { locale: { eq: $locale } }
        }
        sort: { fields: date, order: DESC }
      ) {
        nodes {
          ...BlogPost
      }
    }
  }
`;
