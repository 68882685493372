import styled from 'styled-components';
import { theme } from 'styles/styled';
import Text from 'components/Text';

export const BreadcrumbsWrapper = styled.ul<{ isRtl: boolean }>`
  display: flex;
  flex-wrap: wrap;
  padding-left: ${theme.dimensions.spacingBase16 * 1.25}px;
  direction: ${(props) => (props.isRtl ? 'rtl' : 'ltr')};

  @media ${theme.devices.large} {
    padding-left: 0;
    position: relative;
    z-index: 10;
  }
`;

export const Breadcrumb = styled.li`
  ::after {
    font-size: ${theme.fontSize.labelBig};
    color: ${theme.palette.neutralMid2};
    content: '>';
    margin-left: 8px;
    margin-right: 8px;
    font-weight: ${theme.fontWeight.medium};
  }

  :last-of-type {
    a {
      font-weight: ${theme.fontWeight.medium};
      text-decoration: none;
    }

    ::after {
      content: '';
    }
  }
`;

export const BreadcrumbLink = styled(Text)`
  font-size: ${theme.fontSize.labelBig}px;
  color: ${theme.palette.neutralMid2};
  text-decoration: underline;
  letter-spacing: 0.18px;
  text-transform: capitalize;
`;
