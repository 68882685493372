import React from 'react';

import { Tag as TagType } from 'types';

import Tag from 'components/UI/Tags/Tag';

import { Wrapper } from './styled';

interface Props {
  tags: TagType[];
  handleClick: (tag: TagType) => void;
}

const Tags = ({ tags, handleClick }: Props) => {
  return (
    <Wrapper>
      {tags.map((tag) => (
        <Tag key={tag.name} tag={tag} handleClick={handleClick} />
      ))}
    </Wrapper>
  );
};

export default Tags;
