import React from 'react';
import Img from 'gatsby-image';

import { useIsRtl } from 'hooks/useCurrentLocale';
import { useTranslations } from 'settings/i18n';
import { BlogCard as BlogCardInterface } from 'types';
import { sanitizeHtml, truncateString } from 'utils';

import { ActionLinkRightChevron } from '../../../components';

import {
  Wrapper,
  ImageWrapper,
  TagsWrapper,
  Tag,
  Title,
  Excerpt,
  ReadMore,
  formatTitle,
} from './styled';
import { formatTag } from 'components/UI/Tags/Tag/Tag';

const EXCERPT_LENGTH = 160;

const getExcerpt = (excerpt: string, maxLength: number) =>
  truncateString(sanitizeHtml(excerpt), maxLength);

const BlogCard = ({
  excerpt,
  featuredImage,
  tags,
  title,
}: BlogCardInterface) => {
  const t = useTranslations();
  const isRtl = useIsRtl();

  return (
    <Wrapper>
      <ImageWrapper>
        <Img
          fluid={{
            ...featuredImage.node.localFile.childImageSharp.fluid,
            aspectRatio: 3 / 2,
          }}
          alt={featuredImage.node.altText}
        />
      </ImageWrapper>
      <TagsWrapper>
        {tags.nodes.map((tag) => (
          <Tag key={tag.name}>{t(formatTag(tag))}</Tag>
        ))}
      </TagsWrapper>
      <Title>{formatTitle(title)}</Title>
      <Excerpt isRtl={isRtl}>{getExcerpt(excerpt, EXCERPT_LENGTH)}</Excerpt>
      <ReadMore>
        {t('readMoreLabel')}
        <ActionLinkRightChevron />
      </ReadMore>
    </Wrapper>
  );
};

export default BlogCard;
