import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

import { useTranslations } from 'settings/i18n';
import { BlogPost, Tag as TagType } from 'types';

import menuLinks from 'settings/menuLinks';
import useLinkCreator from 'hooks/useLinkCreator';
import useIsMobile from 'hooks/useIsMobile';

import BlogCard from 'components/Blog/BlogCard';
import Tags from 'components/UI/Tags';
import { SearchInput, SearchIcon } from 'components/EducationGlossary/styled';

import {
  Wrapper,
  Title,
  TitleWrapper,
  TagsWrapper,
  PostsWrapper,
  BlogBreadcrumbs,
  BlogSearchInputWrapper,
} from './styled';

const createTagsObject = (tags: string[]) =>
  tags.map((tag) => ({
    name: tag,
    isActive: false,
  }));

const getActiveTags = (tags: TagType[]) =>
  tags.filter(({ isActive }) => isActive).map(({ name }) => name);

const getUpdatedTags = (tags: TagType[], tag: TagType) =>
  tags.map((tagItem) => {
    if (tagItem.name === tag.name) {
      return {
        ...tagItem,
        isActive: !tagItem.isActive,
      };
    }
    return tagItem;
  });

const hasAnyActiveTag = (activeTags: string[], post: BlogPost) =>
  post.tags.nodes.some(({ name }) => activeTags.includes(name));

const getPostsContainingActiveTags = (
  posts: BlogPost[],
  activeTags: string[],
) => posts.filter((post) => hasAnyActiveTag(activeTags, post));

interface Props {
  blogPosts: BlogPost[];
  wpTags: string[];
}

const Blog = ({ blogPosts, wpTags }: Props) => {
  const t = useTranslations();
  const [posts, setPosts] = useState(blogPosts);
  const [tags, setTags] = useState(createTagsObject(wpTags));
  const [activeTags, setActiveTags] = useState<string[]>([]);
  const [searchPhrase, setSearchPhrase] = React.useState<string>('');

  const createLink = useLinkCreator();
  const isMobile = useIsMobile();

  useEffect(() => {
    const postsContainingActiveTags = getPostsContainingActiveTags(
      blogPosts,
      activeTags,
    );
    setPosts(postsContainingActiveTags);
  }, [activeTags, blogPosts]);

  const handleClick = (tag: TagType) => {
    const updatedTags = getUpdatedTags(tags, tag);
    const newActiveTags = getActiveTags(updatedTags);
    setTags(updatedTags);
    setActiveTags(newActiveTags);
  };

  const postsToShow = activeTags.length === 0 ? blogPosts : posts;

  return (
    <Wrapper>
      <TitleWrapper>
        <Title>{t('blog')}</Title>
        <BlogSearchInputWrapper>
          <SearchInput
            type="search"
            value={searchPhrase}
            onChange={(event) => setSearchPhrase(event.target.value)}
          />
          <SearchIcon />
        </BlogSearchInputWrapper>
      </TitleWrapper>

      {!isMobile && (
        <BlogBreadcrumbs
          links={[
            { to: createLink(menuLinks.home), name: t('home') },
            { to: createLink(menuLinks.blog), name: t('blog') },
          ]}
        />
      )}
      <TagsWrapper>
        <Tags tags={tags} handleClick={handleClick} />
      </TagsWrapper>
      <PostsWrapper>
        {postsToShow
          .filter((post) => {
            if (searchPhrase === '') return true;
            return post.title
              .toLowerCase()
              .includes(searchPhrase.toLowerCase());
          })
          .map(
            (
              { excerpt, featuredImage, id, slug, tags: blogTags, title },
              index,
            ) => (
              // eslint-disable-next-line react/no-array-index-key
              <Link key={`${id}${index}`} to={slug}>
                <BlogCard
                  excerpt={excerpt}
                  featuredImage={featuredImage}
                  slug={slug}
                  tags={blogTags}
                  title={title}
                />
              </Link>
            ),
          )}
      </PostsWrapper>
    </Wrapper>
  );
};

export default Blog;
