import React from 'react';
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';

import styled, { theme, Palette } from 'styles/styled';

import ChevronDownIcon from 'images/Icons/chevron-down';

export const Chevron = styled(ChevronDownIcon)<{
  color?: Palette;
}>`
  height: 10px;
  width: 10px;

  path {
    stroke: ${theme.palette.primary};
  }
`;

export const ActionLinkRightChevron = styled(Chevron)`
  margin-left: 10px;
  transform: rotate(270deg);

  path {
    stroke: ${theme.palette.primary};
  }
`;

export const ActionLinkLeftChevron = styled(Chevron)<{
  color?: Palette;
}>`
  margin-right: 10px;
  transform: rotate(90deg);

  path {
    stroke: ${theme.palette.primary};
  }
`;

export const ActionLink = styled(
  ({
    children,
    ref,
    position = 'right',
    ...props
  }: GatsbyLinkProps<Record<string, unknown>> & {
    position?: 'left' | 'right';
  }) => (
    <GatsbyLink {...props}>
      {position === 'left' && <ActionLinkLeftChevron />}
      {children}
      {position === 'right' && <ActionLinkRightChevron />}
    </GatsbyLink>
  ),
)<{
  color?: Palette;
}>`
  color: ${theme.palette.primary};
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.2678571343421936px;
  text-transform: uppercase;
  text-decoration: none;
`;

export const Initial = styled.div`
  height: 50px;
  min-width: 7px;
  background-color: ${theme.palette.primary};
  margin-right: 44px;
`;

export const Ornament = styled.div`
  width: 100%;
  height: 168px;
  background-color: ${theme.palette.primary};
  clip-path: polygon(0 0, 100% 0, 100% 0, 0% 100%);
`;

export const HomepageSubsectionTitle = styled.h2`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.5px;

  padding: 0 28px;

  strong {
    color: ${theme.palette.primary};
  }

  @media ${theme.devices.large} {
    font-size: 48px;
    line-height: 56px;
    letter-spacing: 1px;
    padding: unset;
  }
`;

export const OutboundLink = styled((props) => (
  /* eslint-disable jsx-a11y/anchor-has-content */
  <a {...props} target="_blank" rel="noopener noreferrer" />
))``;

export const H1 = styled.h1`
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0.5px;
  text-align: left;

  strong {
    font-weight: 500;
  }

  @media ${({ theme }) => theme.devices.medium} {
    font-size: 48px;
    line-height: 56px;
    letter-spacing: 1px;
  }
`;

export const TransparentButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  cursor: pointer;

  :hover {
    opacity: 0.75;
  }
`;

export const H3 = styled.h3`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.3px;
  color: ${({ theme }) => theme.palette.dark};
  text-transform: capitalize;

  @media ${({ theme }) => theme.devices.medium} {
    font-size: 22px;
    line-height: 26px;
    letter-spacing: unset;
  }
`;

export const Paragraph = styled.p`
  font-weight: 300;
  color: ${({ theme }) => theme.palette.dark};
  line-height: 20px;
  font-size: 14px;
  letter-spacing: 0.5px;

  @media ${({ theme }) => theme.devices.medium} {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: unset;
  }
`;
