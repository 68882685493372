import styled from 'styles/styled';
import * as MCDS from 'components/DesignSystem';

export const Wrapper = styled.article`
  color: ${({ theme }) => theme.palette.dark};
  transition: ${({ theme }) => theme.ui.transition('opacity')};
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    opacity: ${({ theme }) => theme.ui.opacity()};
  }
`;

export const ImageWrapper = styled.div`
  margin-bottom: 20px;
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
`;

export const Tag = styled.h3`
  //styleName: mobile/m_H4;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.25px;

  color: ${({ theme }) => theme.palette.primary};
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;

  &:not(&:last-of-type) {
    &:after {
      content: '';
      display: inline-flex;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.palette.neutralDark4};
      margin: 0 7px;
    }
  }
`;

export const Title = styled.h2`
  //styleName: mobile/m_H3;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.30000001192092896px;

  margin-bottom: 15px;
`;

export const Excerpt = styled(MCDS.Paragraph)`
  text-align: left;
  margin-bottom: 20px;
`;

export const ReadMore = styled.p`
  //styleName: mobile/m_H4;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.25px;

  color: ${({ theme }) => theme.palette.primary};
  text-align: center;
  text-transform: uppercase;
`;

export function formatTitle(title: string): string {
  const index = title.indexOf('|');

  if (index === -1) return title;

  return title
    .split('')
    .filter((_, i) => i < index)
    .join('');
}
