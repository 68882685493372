import styled from 'styles/styled';

import { SearchInputWrapper } from 'components/EducationGlossary/styled';
import Breadcrumbs from 'components/Breadcrumbs';

export const Wrapper = styled.div`
  padding: 150px 15px;
  margin: 0 auto;
  max-width: 1360px;

  @media ${({ theme }) => theme.devices.xxlarge} {
    padding: 200px 0;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 15px;

  @media ${({ theme }) => theme.devices.medium} {
    flex-direction: row;
    align-items: center;
  }
`;
export const Title = styled.h1`
  display: flex;
  color: ${({ theme }) => theme.palette.primary};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const TagsWrapper = styled.div``;

export const PostsWrapper = styled.section`
  margin-top: 40px;
  display: grid;
  grid-gap: 35px;

  @media ${({ theme }) => theme.devices.xsmall} {
    grid-template-columns: repeat(auto-fill, minMax(300px, 1fr));
  }

  @media ${({ theme }) => theme.devices.xlarge} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const BlogBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: 32px;
  padding-left: 0;
`;

export const BlogSearchInputWrapper = styled(SearchInputWrapper)`
  max-width: 420px;
  width: 100%;
  margin: 14px auto 0 auto;

  @media ${({ theme }) => theme.devices.medium} {
    margin: 0 0 0 auto;
  }
`;
