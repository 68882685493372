import React from 'react';
import { Link } from 'gatsby';

import { useIsRtl } from 'hooks/useCurrentLocale';

import { BreadcrumbsWrapper, Breadcrumb, BreadcrumbLink } from './styled';

type BreadcrumbLink = { to: string; name: string };

const Breadcrumbs = ({ links }: { links: BreadcrumbLink[] }) => {
  const isRtl = useIsRtl();

  return (
    <BreadcrumbsWrapper isRtl={isRtl}>
      {links.map(({ to, name }) => (
        <Breadcrumb key={name}>
          <BreadcrumbLink isRtl={isRtl} as={Link} to={to}>
            {name.toLowerCase()}
          </BreadcrumbLink>
        </Breadcrumb>
      ))}
    </BreadcrumbsWrapper>
  );
};

export default Breadcrumbs;
