import React from 'react';

import { useTranslations } from 'settings/i18n';

import { Tag as TagType } from 'types';

import { Wrapper } from './styled';

interface Props {
  tag: TagType;
  handleClick: (tag: TagType) => void;
}

const Tag = ({ tag, handleClick }: Props) => {
  const t = useTranslations();

  return (
    <Wrapper isActive={tag.isActive} onClick={() => handleClick(tag)}>
      {t(formatTag(tag))}
    </Wrapper>
  );
};

export function formatTag(tag: Omit<Props['tag'], 'isActive'>): string {
  return tag?.name?.split(' ')?.[0]?.toLowerCase();
}

export default Tag;
