import React from 'react';

function SearchIcon(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <path
              d="M0 0H30V30H0z"
              transform="translate(-1155 -3001) translate(1155 3001)"
            />
            <path
              fill="#28AAFF"
              d="M21.7 19.756c3.559-4.591 2.94-11.16-1.416-15.003C15.93.91 9.338 1.113 5.228 5.22 1.115 9.33.906 15.93 4.751 20.29c3.846 4.361 10.418 4.978 15.006 1.408l.06.062 5.834 5.838c.347.348.854.483 1.329.356s.846-.498.973-.973-.008-.982-.356-1.33l-5.836-5.837c-.02-.02-.04-.039-.062-.057zM18.61 7.297c2.049 2.016 2.859 4.975 2.123 7.752-.736 2.778-2.906 4.948-5.684 5.684-2.777.736-5.736-.074-7.752-2.123-3.08-3.13-3.06-8.16.046-11.267 3.106-3.106 8.136-3.127 11.267-.046z"
              transform="translate(-1155 -3001) translate(1155 3001)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SearchIcon;
